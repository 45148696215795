<template>
    <div class="content">功能未开放... </div>
</template>
<script>
export default {
    data(){
        return{}
    }
}
</script>
<style lang="scss" scoped>
.content{
    padding-top: 20px;
    padding-left: 20px;
}
</style>